import { voidFunction } from "~utils/functions";

import { forgetPassword, login, logout, verifyCode } from "./repository";
import type { PostLoginRequest, UseForgetPasswordType, UseLoginType, UseLogoutType, UseVerifyCodeType } from "./types";

import { useMutation } from "react-query";

export const useLogin: UseLoginType = (onSuccessFn = voidFunction, onErrorFn = voidFunction) => {
  return useMutation({
    mutationKey: ["loginRequest"],
    mutationFn: async (formData: PostLoginRequest) => login(formData),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};

export const useLogout: UseLogoutType = (onSuccessFn = voidFunction, onErrorFn = voidFunction) => {
  return useMutation({
    mutationKey: ["loginRequest"],
    mutationFn: async () => logout(),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};

export const useForgetPassword: UseForgetPasswordType = (onSuccessFn = voidFunction, onErrorFn = voidFunction) => {
  return useMutation({
    mutationKey: ["forget-password-request"],
    mutationFn: async (dto) => forgetPassword(dto),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};

export const useVerifyCode: UseVerifyCodeType = (onSuccessFn = voidFunction, onErrorFn = voidFunction) => {
  return useMutation({
    mutationKey: ["verify-code-request"],
    mutationFn: async (dto) => verifyCode(dto),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};
