import theme from "~providers/muiThemeProvider/mui-theme";

import styled from "@emotion/styled";
import { Select } from "@mui/material";

interface Props {
  backgroundColor: string | "transparent";
}

export const StyledSelect = styled(Select)<Props>`
  width: 100%;
  background-color: ${(props) => props.backgroundColor} !important;

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.primary.main};
    transition: border-color 0.3s ease-out;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${theme.palette.primary.main};
  }

  .MuiList-root {
    background-color: red !important;
  }
`;
