import theme from "~providers/muiThemeProvider/mui-theme";

import { Column } from "../flexs/Column";
import { Body1 } from "../typography/Body1";

import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

export const Sc = {
  Wrapper: styled(Column)({
    minHeight: "100vh",
    width: "100%",
    color: theme.palette.primary[500],
  }),
  CircularProgress: styled(CircularProgress)({
    color: "inherits",
  }),
  Title: styled(Body1)({
    marginTop: "10px !important",
  }),
};
