import { LayoutType } from "~layouts/config";
import isNotAuthenticated from "~src/common/guards/isNotAuthenticated";
import LoginTemplate from "~src/templates/login";
import type { NextPage } from "~types/next";

import { NextSeo } from "next-seo";

const LoginPage: NextPage = () => {
  return (
    <div>
      <NextSeo title="Login" />
      <LoginTemplate />
    </div>
  );
};

LoginPage.layout = LayoutType.Login;

export default isNotAuthenticated(LoginPage);
