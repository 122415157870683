import "react-phone-input-2/lib/style.css";

import theme from "~providers/muiThemeProvider/mui-theme";
import { isNullOrUndefined } from "~utils/boolean-utils";
import { voidFunction } from "~utils/functions";
import type { Consumer, Nullable } from "~utils/types";

import { InputError } from "../errors/InputError";

import { Sc } from "./CustomPhoneInput.style";

import type { FC, PropsWithChildren } from "react";
import type { PhoneInputProps } from "react-phone-input-2";

interface Props extends PhoneInputProps, PropsWithChildren {
  value?: Nullable<string>;
  onChange?: Consumer<string>;
  error?: string | undefined;
  backgroundColor?: string | "transparent";
}

export const CustomPhoneInput: FC<Props> = ({
  value = "",
  onChange = voidFunction,
  children,
  backgroundColor = theme.palette.common.white,
  error = undefined,
}) => {
  return (
    <>
      <Sc.Wrapper>
        <Sc.StyledPhoneInput
          country={"ma"}
          value={value}
          onChange={onChange}
          isValid={isNullOrUndefined(error)}
          masks={{ ma: ". .. .. .. .." }}
          enableSearch={true}
          backgroundColor={backgroundColor}
        ></Sc.StyledPhoneInput>
        <Sc.EndIconWrapper>{children}</Sc.EndIconWrapper>
      </Sc.Wrapper>
      {Boolean(error) && <InputError error={Boolean(error)}>{error}</InputError>}
    </>
  );
};
