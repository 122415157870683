import Loading from "~src/common/components/loadingPage/LoadingPage";
import { useRedirectToPrivatePage } from "~src/common/hooks/redirection";
import { useGetCurrentUser } from "~store/current-user/hooks";
import type { NextPage } from "~types/next";
import { voidFunction } from "~utils/functions";

export const isNotAuthenticated = (WrappedPage: NextPage): NextPage => {
  const ResultPage: NextPage = () => {
    const { redirectUser } = useRedirectToPrivatePage();
    const { user, isError, isLoading } = useGetCurrentUser();

    if (isLoading) {
      return <Loading />;
    }
    if (!(user === null)) {
      redirectUser(user).catch(voidFunction);
    }
    if (isError) {
      return <WrappedPage />;
    }

    return null;
  };

  if (WrappedPage.layout !== undefined) ResultPage.layout = WrappedPage.layout;

  return ResultPage;
};
