import theme from "~providers/muiThemeProvider/mui-theme";

import styled from "@emotion/styled";
import PhoneInput from "react-phone-input-2";

interface PhoneInputProps {
  backgroundColor: string;
}

export const Sc = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  `,
  StyledPhoneInput: styled(PhoneInput)<PhoneInputProps>`
    width: 100% !important;

    & .form-control {
      width: 100% !important;
      height: 56px !important;
      border-color: ${theme.palette.primary[500]} !important;
      border-radius: 12px !important;
      padding-left: 50px !important;
      background-color: ${(props) => props.backgroundColor} !important;
    }
    & .form-control:focus,
    .form-control:hover {
      border-width: 2px !important;
    }

    & .flag-dropdown {
      border-top-left-radius: 12px !important;
      border-bottom-left-radius: 12px !important;
      border-color: ${theme.palette.primary[500]} !important;
    }

    & .selected-flag {
      background-color: transparent !important;
      padding-inline: 20px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .open {
      border-width: 2px;
    }

    & .invalid-number {
      border-color: red !important;
    }
    & .invalid-number:hover {
      border-color: ${theme.palette.primary[500]} !important;
    }
  `,
  EndIconWrapper: styled.div`
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    z-index: 20;
  `,
};
