import { Row } from "~src/common/components/flexs/Row";
import { sizes } from "~utils/responsive/breakPointsResponsive";

import { styled } from "@mui/material";
import Image from "next/image";

const Sc = {
  Row: styled(Row)({
    justifyContent: "flex-start",
    flex: 1,
    height: "100vh",
    width: "100%",
    [`@media (max-width: ${sizes.sm})`]: {
      justifyContent: "center",
    },
  }),
  ImageContainer: styled("div")({
    position: "relative",
    flex: 1,
    height: "100vh",
    [`@media (max-width: ${sizes.sm})`]: {
      display: "none",
    },
  }),
  CardContainer: styled("div")({
    width: "50%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }),
  StyledImage: styled(Image)({
    objectFit: "cover",
  }),
};

export default Sc;
