import theme from "~providers/muiThemeProvider/mui-theme";
import PrimaryButton from "~src/common/components/buttons/primaryButton/PrimaryButton";
import { Column } from "~src/common/components/flexs/Column";
import { Body1 } from "~src/common/components/typography/Body1";

import { styled } from "@mui/material";

const Sc = {
  StyledPrimaryButton: styled(PrimaryButton)({ height: "40px" }),

  InputsContainer: styled(Column)({
    gap: "30px",
    width: "350px",
  }),
  ForgetPasswordButton: styled(Body1)`
    cursor: pointer;
    color: ${theme.palette.primary[300]};
  `,
};

export default Sc;
