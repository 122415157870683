import { Paths } from "~utils/paths";
import type { User } from "~utils/types/global-types";

import type { UseRedirectToPrivatePageType, UseRedirectUserType } from "./types";

import { useRouter } from "next/router";

export const useRedirectToPrivatePage: UseRedirectToPrivatePageType = () => {
  const router = useRouter();

  async function redirectUser(_user: User) {
    // const roles: AllowedRoles = ["tech", "marketing"];
    // const userRole = user.role.code;
    await router.push(Paths.Tasks);

    // if (userRole === undefined || roles.includes(userRole)) {
    // } else {
    // }
  }

  return { redirectUser };
};

export const useRedirectUser: UseRedirectUserType = () => {
  const router = useRouter();

  const redirectToFn = async (path: Paths) => {
    await router.push(path);
  };

  return {
    redirectTo: redirectToFn,
  };
};
