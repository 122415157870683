import theme from "~providers/muiThemeProvider/mui-theme";
import { isDefined } from "~utils/boolean-utils";

import { InputError } from "../errors/InputError";

import { StyledSelect } from "./CustomSelect.style";

import type { SelectProps } from "@mui/material";
import type { FC } from "react";

type Props = Omit<SelectProps, "variant"> & {
  helperText?: string | undefined;
  backgroundColor?: string | "transparent";
};

export const CustomSelect: FC<Props> = ({
  helperText,
  backgroundColor = theme.palette.common.white,
  children,
  ...props
}) => {
  return (
    <>
      <StyledSelect backgroundColor={backgroundColor} {...props}>
        {children}
      </StyledSelect>
      {isDefined(helperText) && <InputError error={true}>{helperText}</InputError>}
    </>
  );
};
