import { forgetPasswordUrl, loginUrl, logoutUrl, verificationCodeUrl } from "~api/urls";
import axiosInstance from "~utils/axiosInstance";
import type { User } from "~utils/types/global-types";

import type { ForgetPasswordDTO, ForgetPasswordResponseDTO, PostLoginRequest, VerificationCodeDTO } from "./types";

import type { AxiosResponse } from "axios";

export const login = async (loginRequest: PostLoginRequest): Promise<User> => {
  const { data }: AxiosResponse<User> = await axiosInstance.post(loginUrl, loginRequest);

  return data;
};

export const logout = async (): Promise<void> => {
  await axiosInstance.post(logoutUrl);
};

export const forgetPassword = async (dto: ForgetPasswordDTO): Promise<ForgetPasswordResponseDTO> => {
  const { data } = await axiosInstance.post<ForgetPasswordResponseDTO>(forgetPasswordUrl, dto);

  return data;
};

export const verifyCode = async (dto: VerificationCodeDTO): Promise<void> => {
  await axiosInstance.put(verificationCodeUrl, dto);
};
