import { useState } from "react";

import RoundTextField from "../roundTextField";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import type { TextFieldProps } from "@mui/material";
import { IconButton, InputAdornment } from "@mui/material";
import type { FC } from "react";

type PasswordTextFieldProps = TextFieldProps;

const PasswordTextField: FC<PasswordTextFieldProps> = ({ ...props }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };
  return (
    <RoundTextField
      type={isVisible ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleVisibility}>{isVisible ? <Visibility /> : <VisibilityOff />}</IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordTextField;
