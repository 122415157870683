import { Sc } from "./LoadingPage.style";

import type { FC } from "react";

const LoadingPage: FC = () => {
  return (
    <Sc.Wrapper alignItems="center" justify="center">
      <Sc.CircularProgress size={"50px"} />
      <Sc.Title value="Loading..." />
    </Sc.Wrapper>
  );
};

export default LoadingPage;
