import { useTranslate } from "~i18n/hooks";
import theme from "~providers/muiThemeProvider/mui-theme";
import Logo from "~src/assets/icons/logo";
import { Column } from "~src/common/components/flexs/Column";
import { Body1 } from "~src/common/components/typography/Body1";

import type { FC } from "react";

const LoginCardHeader: FC = () => {
  const translate = useTranslate();
  return (
    <Column alignItems="center" rowGap={40}>
      <Logo />
      <Body1 value={translate("login.card.subTitle")} color={theme.palette.grey[500]} textAlign="center" />
    </Column>
  );
};

export default LoginCardHeader;
