import LoginCard from "./components/loginCard";
import Sc from "./LoginTemplate.style";

import type { FC } from "react";
import imageLogin from "src/assets/images/login.jpg";

export const LoginTemplate: FC = () => {
  return (
    <Sc.Row>
      <Sc.ImageContainer>
        <Sc.StyledImage alt="background image" src={imageLogin} fill />
      </Sc.ImageContainer>
      <Sc.CardContainer>
        <LoginCard />
      </Sc.CardContainer>
    </Sc.Row>
  );
};
