import { Column } from "~src/common/components/flexs/Column";
import { Row } from "~src/common/components/flexs/Row";

import LoginCardBody from "./components/loginCardBody/LoginCardBody";
import LoginCardHeader from "./components/loginCardHeader/LoginCardHeader";

import type { FC } from "react";

export const LoginCard: FC = () => {
  return (
    <Row justify="center">
      <Column rowGap={60} alignItems="center">
        <LoginCardHeader />
        <LoginCardBody />
      </Column>
    </Row>
  );
};
